window.cpexSkinVersion = '0.8 removed sending of logs';

if (window.location.href.indexOf("cpexsrc=test") > -1 && typeof CPEX_testSkinSourceCalled === 'undefined') {
    var CPEX_testSkinSourceCalled = true;
    var customSkinScript = document.createElement('script');
    customSkinScript.async = true;
    customSkinScript.type = 'text/javascript';
    customSkinScript.src = 'https://test.cpex.cz/skin/v2/cpex-skin.js';
    var node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(customSkinScript, node);
} else {
    // cpex timer start
    try {
        window.cpexTimer = cpexTimer || {};
        cpexTimer.que = cpexTimer.que || [];
        cpexTimer.que.push(['creative_init_skin', performance.now()]);
    } catch (e) { }

    function CPExSkinObject () {
        // IE8 hack
        if (typeof window.console === "undefined" || typeof window.console.log === "undefined") {
            window.console = {};
            window.console.log = function (msg) {
            };
            window.console.error = function (msg) {
            };
        }

        var browserName = 'not-detected';
        try {
            if ((window.opr && opr.addons) || window.opera || (navigator.userAgent.indexOf(' OPR/') >= 0)) browserName = "Opera";
            if (typeof InstallTrigger !== 'undefined') browserName = "Firefox";
            if (/constructor/i.test(window.HTMLElement)
                || (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(!window['safari'] || safari.pushNotification))
                browserName = "Safari";
            if ((/*@cc_on!@*/false) || (document.documentMode)) browserName = "IE";
            if (!(document.documentMode) && window.StyleMedia) browserName = "Edge";
            if (window.chrome && window.chrome.webstore) browserName = "Chrome";
        } catch (e) {
        }

        var defaults = {
            /* Prototyp objektu, ktery zajistuje fungovani skinu */
            creativeURL: undefined, /* URL obrazku se zakladni kreativou velikosti 2000x1400 px - povinne */
            measurePixel: undefined, /* URL obrazku pro traking imprese - nepovinne, lec doporucene pouzivat */
            clickURL: undefined, /* cilove URL pro proklik - povinne */
            creativeURLw: undefined, /* URL obrazku s kreativou pro siroky obsah - nepovinne */
            creativeURLs: undefined, /* URL obrazku s kreativou pro nizky (100px) vrsek - nepovinne */
            backgroundColor: "#ffffff", /* barva pozadi mimo obrazek - nepovinne, default bila */
            title: "", /* title - tooltip pro odkaz */
        };

        /* URL, kam se posílají chyby - nemenit pro produkcni prostredi */
        var minRenderWidth = 1150;
        /* minimalni sirka v px, kdy se vykresli branding */

        this.getParameterByName = function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) {
                regex = new RegExp("[#&]" + name + "(=([^&#]*)|&|#|$)");
                results = regex.exec(url);
                if (!results) {
                    return null;
                }
            }
            if (!results[2]) {
                return '';
            }
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };

        var extendDefaults = function (source, properties) {
            var property;
            for (property in properties) {
                if (properties.hasOwnProperty(property)) {
                    source[property] = properties[property];
                }
            }
            return source;
        };

        this.debug = !!(this.getParameterByName('debug') === '1' || window.cpexSkinDebug);
        this.version = window.cpexSkinVersion;
        this.isReadyToRender = false;

        // create options by extending defaults with the passed in arguments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0]);
        } else {
            this.options = defaults;
        }

        this.setOptions = function (options) {
            if (options && typeof options === "object") {
                this.options = extendDefaults(defaults, options);
            }
        };

        this.checkMinWidth = function () {
            var w;
            try {
                w = matchMedia('(min-width: ' + minRenderWidth + 'px)').matches;
            } catch (e) {
                w = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > minRenderWidth);
            }
            if (!w) {
                if (this.debug) { this.log("Insufficient viewport width") }
                return false;
            } else {
                return true;
            }
        };

        this.isBrowserSupported = function () {
            try {
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf('MSIE ');
                if (msie > 0) {
                    // IE 10 or older => return version number
                    return !((parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)) < 10);
                }
            } catch (e) { }
            return true;
        };

        this.checkReadyToRender = function () {
            this.isReadyToRender = (this.checkMinWidth() && typeof cpexSkin === 'function' && this.isBrowserSupported());
            return this.isReadyToRender;
        };

        this.log = function (message) {
            if (this.debug) {
                console.log(message);
            }
        };

        this.logTestUrl = function () {

            function removeLoadskin (string, delimiter) {
                if (!delimiter) {
                    delimiter = '?';
                }
                var objURL = {};
                string.replace(
                    new RegExp("([^" + delimiter + "=&]+)(=([^&]*))?", "g"),
                    function ($0, $1, $2, $3) {
                        objURL[$1] = $3;
                    }
                );
                return objURL
            }

            function serialize (obj) {
                var str = "";
                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        if (str !== "") {
                            str += "&";
                        }
                        str += key + "=" + encodeURIComponent(obj[key]);
                    }
                }
                return str;
            }

            if (!this.options.scriptSrc
                && !!this.options.creativeURL
                && this.options.creativeURL.indexOf('cdn.cpex') !== -1) {
                var sp = this.options.creativeURL.split('/');
                this.options.scriptSrc = sp[sp.length - 2];
            }

            if (this.options.scriptSrc) {
                var objURL;
                var url = location.origin + location.pathname;
                if (location.search !== '') {
                    objURL = removeLoadskin(location.search, '?');
                    delete objURL['cpexloadskin'];
                    delete objURL['cpexloadskinurl'];
                    url += '?' + serialize(objURL);
                }

                if (location.hash !== '') {
                    objURL = removeLoadskin(location.hash, '#');
                    delete objURL['cpexloadskin'];
                    delete objURL['cpexloadskinurl'];
                    objURL['cpexloadskinurl'] = this.options.scriptSrc;
                    url += '#' + serialize(objURL);
                } else {
                    url += '#cpexloadskinurl=' + this.options.scriptSrc;
                }

                console.log(url);
            }
        };

        this.testBeforeRender = function () {

            /* funkce pro vykresleni skinu */
            if (this.isRendered) {
                return false;
            }

            if (!this.options.creativeURL) {
                // Missing creative URL
                return false;
            }

            // if (!this.options.clickURL) {
            //     Missing click URL
            //}

            if (!this.checkMinWidth()) {
                if (!this.isReadyToRender) {
                    // isReadyToRender = false
                    return false;
                }
            }

            /* kod, ktery osetri http / https // tak, aby nevyhodily chybu */
            if (location.protocol === 'https:') {
                for (var option in this.options) {
                    if (this.options.hasOwnProperty(option)
                        && typeof this.options[option] === 'string' && this.options[option].substring(0, 5) === 'http:') {
                        // Calling http from https domain in: ' + option
                        this.options[option] = this.options[option].replace("http:", "https:");
                    }
                }
            }

            if (typeof cpexSkin !== 'function') {
                // Missing render function cpexSkin. Skin not rendered
                return false;
            }

            return true;
        };

        function loadScript (src, scriptID, node) {
            if (!src) {
                return;
            }
            if (!scriptID) {
                scriptID = 'cpexloadskin';
            }
            if (!document.getElementById(scriptID)) {
                var scriptEl = document.createElement('script');
                scriptEl.async = true;
                scriptEl.id = scriptID;
                scriptEl.type = 'text/javascript';
                scriptEl.src = src;
                if (!node) {
                    node = document.getElementsByTagName('script')[0];
                }
                node.parentNode.insertBefore(scriptEl, node);
            }
        };

        this.preparePage = function () {
            if (this.testBeforeRender()) {
                /* zde je kod pro vykresleni skinu specificky pro danou stranku ci publishera */
                var elements = cpexSkin(this);
                /* support for fixed header offset */
                if (elements) {
                    if (elements.fixedHeader) {
                        // load fixed header script
                        top.window.cpexFixedHeader = elements.fixedHeader;
                        loadScript('https://cdn.cpex.cz/skin/v2/cpex-skinOffset.js', 'fixedHeaderScript');
                    }
                    if (typeof elements.skinWrapper !== 'undefined') {
                        this.elements = elements;
                    }
                    return elements;
                } else {
                    console.error('Missing render elements');
                }
                /* pokud se kod vykreslil, nastavi se rendered */
                this.isRendered = true;
            }
        };

        this.resetSkin = function () {
            /* not 100% working */
            this.isRendered = false;
            try {
                if (this.elements) {
                    if (this.elements.skinWrapper) {
                        this.elements.skinWrapper.parentNode.removeChild(this.elements.skinWrapper);
                    }
                    if (this.elements.scrollElement) {
                        this.elements.scrollElement.style.backgroundImage = 'none';
                    }
                }
                if (typeof cpexResetSkin === 'function') {
                    cpexResetSkin();
                }
            } catch (e) {
                console.error(e);
            }
        };

        this.insertTest = function (position) {
            try {
                var element;
                if (position) {
                    element = this.getElement(position);
                }
                var src = this.getParameterByName('cpexload');
                var version = this.getParameterByName('version');
                if (version === 'script') {
                    loadScript('https://' + src, 'generator-test', element);
                } else if (version === 'html5') {
                    var iFrame = top.document.createElement('iframe');
                    iFrame.setAttribute('style', 'width:0px; height:0px; overflow:hidden; border:none; margin:0 auto; display:block');
                    iFrame.setAttribute('scrolling', 'no');
                    iFrame.setAttribute('marginwidth', '0');
                    iFrame.setAttribute('marginheight', '0');
                    iFrame.src = 'https://' + src;
                    if (!element) {
                        element = document.body;
                    }
                    element.appendChild(iFrame);
                }
            } catch (e) {
                console.error(e);
            }
        };

        this.getElement = function (customEl, doc) {
            try {
                if (!doc) {
                    doc = document;
                }

                if (typeof customEl === 'function') {
                    customEl = (customEl.bind(this))();
                }

                if (customEl instanceof Element || (typeof customEl === 'object' && customEl.nodeName)) {
                    return customEl;
                } else if (typeof customEl === 'string') {
                    var el = doc.querySelector(customEl);
                    if (el) {
                        return el;
                    } else {
                        el = doc.getElementById(customEl);
                        if (el) {
                            return el;
                        } else {
                            el = doc.getElementsByClassName(customEl)[0];
                            if (el) {
                                return el;
                            } else {
                                if (doc !== window.top.document) {
                                    return this.getElement(customEl, window.parent.document);
                                }
                            }
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        this.getParameterByName = function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) {
                regex = new RegExp("[#&]" + name + "(=([^&#]*)|&|#|$)");
                results = regex.exec(url);
                if (!results) {
                    return null;
                }
            }
            if (!results[2]) {
                return '';
            }
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };

        // Rendering

        this.prepareSkinIframe = function () {
            var friendlyIFrame = true;
            try {
                try {
                    var cpexSkinObject = window.top.cpexSkinObject =
                        new top.window.CPExSkinObject({
                            creativeURL: 'creativeURL-substitution ',
                            clickURL: 'clickURL-substitution'
                        });
                } catch (e) {
                    friendlyIFrame = false;
                }
                if (friendlyIFrame) {
                    if (typeof top.window.CPExSkinObject !== 'undefined') {
                        if (cpexSkinObject.isReadyToRender) {
                            var elements = cpexSkinObject.preparePage();

                            if (typeof elements.skinWrapper === 'undefined') {
                                var skinWrapperRep = top.document.getElementById('cpex-skin-wrapper');
                                if (skinWrapperRep) {
                                    elements.skinWrapper = skinWrapperRep;
                                } else {
                                    // missing skinWrapper element
                                    return null;
                                }
                            }

                            if (typeof elements.contentElement === 'undefined') {
                                // missing content element
                                return null;
                            }

                            try {
                                /* iframe render */
                                var iFrameWrapper = top.document.createElement('div');
                                iFrameWrapper.setAttribute("style", 'position: fixed;width: 100%; height: 100%; top:0; z-index:0;overflow: hidden');
                                elements.skinWrapper.appendChild(iFrameWrapper);

                                var iFrameWrapperCenter = top.document.createElement('div');
                                iFrameWrapperCenter.setAttribute("style", 'position: absolute;top:0;left:50%;margin-left:-1000px;');
                                iFrameWrapper.appendChild(iFrameWrapperCenter);

                                /* iFrameWrapper.insertAdjacentHTML('afterbegin', iFrameValue); */
                                var iFrame = top.document.createElement('iframe');
                                iFrame.setAttribute("style", 'width:2000px;height:1400px;overflow: hidden;border: none;margin: 0 auto; display: block');
                                iFrame.setAttribute('marginwidth', '0');
                                iFrame.setAttribute('marginheight', '0');
                                iFrame.setAttribute("scrolling", 'no');
                                iFrameWrapperCenter.appendChild(iFrame);

                                return iFrame;
                                /* iframe render - END */
                            } catch (e) {
                                console.error('basicIframe: ' + e);
                            }
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
            return null;
        };

        this.render = function () {
            var that = this;
            if (this.testBeforeRender()) {
                try {
                    /* zde je kod pro vykresleni skinu specificky pro danou stranku ci publishera */
                    var elements = cpexSkin(this);
                    if (elements) {
                        try {
                            elements.skinWrapper.title = this.options.title || '';
                        } catch (e) {
                            console.error('addTitle: ' + e);
                        }

                        try {
                            var skinWrapper = elements.skinWrapper;
                            skinWrapper.style.backgroundImage = "url(" + this.options.creativeURL + ")";
                            skinWrapper.style.backgroundColor = this.options.backgroundColor;
                            skinWrapper.style.backgroundRepeat = "no-repeat";
                            skinWrapper.style.backgroundPosition = "center top";
                            skinWrapper.style.backgroundAttachment = "fixed";
                            skinWrapper.style.cursor = "pointer";
                        } catch (e) {
                            console.error('basic: ' + e);
                        }

                        try {
                            if (this.options.clickURL) {
                                elements.skinWrapper.addEventListener("click", function () {
                                    location.href = that.options.clickURL;
                                });
                            }
                        } catch (e) {
                            console.error('click: ' + e);
                        }

                        if (this.options.measurePixel) {
                            var i = new Image();
                            i.src = this.options.measurePixel;
                            // Slouzi k zalogovani pri nedostupnosti measurePixel - nepovinne
                            i.onerror = function () {
                                console.error("Chybne zadany nebo nedostupny measurePixel");
                            };
                            i.style.display = 'none';
                            top.document.body.appendChild(i);
                        }

                    } else {
                        console.error('Missing render elements');
                    }

                    /* pokud se kod vykreslil, nastavi se rendered */
                    this.isRendered = true;

                } catch (e) {
                    console.error(e);
                }
            }
        };

        this.renderSkinCreativeIntoIframe = function (creative, targetIframe) {
            if (!targetIframe) {
                targetIframe = this.prepareSkinIframe();
            }
            if (targetIframe) {
                try {
                    var frameDoc = targetIframe.document;
                    if (targetIframe.contentWindow) {
                        frameDoc = targetIframe.contentWindow.document;
                    }
                    frameDoc.open();
                    frameDoc.write('<!DOCTYPE html>' +
                        (creative.indexOf('<html>') === -1)
                        ? '<html><head><style>body {margin: 0; padding: 0}</style></head><body>' + creative + '</body></html>'
                        : creative
                    );
                    frameDoc.close();
                } catch (e) {
                    console.error(e);
                }
            } else {
                console.error('Missing cpexSkin function');
            }
            return targetIframe;
        };

        // Initialization

        if (typeof cpexSkin !== 'function') {
            console.error('Missing render function cpexSkin on object init.');
        }

        if (this.checkReadyToRender()) {
            var that = this;
            (function () {
                function checkUrlForSkin () {
                    setTimeout(function () {
                        var loadSkin = that.getParameterByName('cpexloadskin');
                        if (loadSkin) {
                            loadScript('https://cdn.cpex.cz/skin/js/skin-' + loadSkin + '.js');
                        } else {
                            loadSkin = that.getParameterByName('cpexloadskinurl');
                            if (loadSkin) {
                                loadScript('https://' + loadSkin);
                            }
                        }
                    }, 3000);
                }
                if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
                    checkUrlForSkin();
                } else {
                    document.addEventListener("DOMContentLoaded", checkUrlForSkin);
                }
            })();
        }

    }

    // Loader support

    (function () {
        if (typeof cpexLoader !== 'undefined'
            && typeof cpexLoader.useSequential !== 'undefined'
            && !cpexLoader.useSequential
        ) {
            var cpexSkinInterval;
            var callback = function () {
                try {
                    window.top.cpexSkinObject = new CPExSkinObject();
                    cpexLoader.log('--------------- cpexSkinObject ----------------');
                } catch (e) {
                    cpexLoader.log(e);
                }
                cpexLoader.emitEvent('cpexSkinObject');
                clearInterval(cpexSkinInterval);
            };
            if (typeof cpexSkin === 'function') {
                callback();
            } else {
                if (typeof cpexLoader !== 'undefined' && typeof cpexLoader.emitEvent === 'function' && typeof cpexSkin === 'undefined') {
                    cpexSkinInterval = setInterval(function () {
                        if (typeof cpexSkin === 'function') {
                            cpexLoader.emitEvent('cpexSkin');
                            try {
                                CPExSkinObject.log('------------------------- cpexSkin interval -----------------------');
                            } catch (e) { }
                        }
                    }, 50);
                }
                cpexLoader.waitFor('cpexSkin', callback);
            }
        } else {
            window.top.cpexSkinObject = new CPExSkinObject();
            window.top.cpexSkinObject.log('--------------- cpexSkinObject useSequential ----------------');
        }
    })();
}

// HTML5 skin support

if (window.top.location.href.indexOf("cpexsrc=test") > -1 && typeof CPEX_testHTML5Called === 'undefined') {
    var CPEX_testHTML5Called = true;

    (function () {
        var ppas = document.createElement('script');
        ppas.async = true;
        ppas.type = 'text/javascript';
        ppas.src = 'https://test.cpex.cz/html5/v1/cpex-html5.js';
        var node = document.getElementsByTagName('script')[0];
        node.parentNode.insertBefore(ppas, node);
    })();

} else {
    var cpexLoadTest = false;
    try {
        cpexLoadTest = (window.top.location.href.indexOf("cpexsrc=test") > -1);
    } catch (e) {
        try {
            var me = document.currentScript;
            if (me && typeof me.src !== 'undefined' && (me.src.indexOf('test.cpex') > -1 || me.src.indexOf('4443') > -1)) {
                cpexLoadTest = true;
            }
        } catch (e) { }
    }
    try {
        (function () {
            var showLogs = false;
            try {
                showLogs = (window.top.location.href.indexOf("debug=1") > -1);
            } catch (e) {
                showLogs = false;
            }
            var log = function (message) {
                if (showLogs) {
                    try {
                        console.log(message);
                    } catch (e) {
                    }
                }
            };

            var getIframe = function (ev) {
                var source = ev.source;

                if (typeof ev.data.source === 'string') {
                    var framesSource = document.querySelectorAll(ev.data.source);
                    if (framesSource) {
                        return [framesSource[0]];
                    }
                }

                var frames = document.getElementsByTagName('iframe');
                for (var i = 0; i < frames.length; i++) {
                    if (frames[i].contentWindow === source) {
                        return [frames[i]];
                    }
                    try {
                        var innerFrames = frames[i].contentWindow.document.getElementsByTagName('iframe');
                        for (var j = 0; j < innerFrames.length; j++) {
                            if (innerFrames[j].contentWindow === source) {
                                return [innerFrames[j], frames[i]];
                            }
                        }
                    } catch (e) {}
                }
                return null;
            };

            var loadHTML5Script = function (type, data, appendAfter) {
                var id = Math.random().toString(36).substring(8);

                var s = document.createElement('script');
                s.src = 'https://' + ((cpexLoadTest) ? 'test' : 'cdn') + '.cpex.cz' + '/caf/v1/html5/' + type + '.js';
                s.id = id;
                s.onload = function () {
                    try {
                        window.cpexHtml5[type](data, id);
                    } catch (e) {
                        console.error(e);
                    }
                }
                s.onerror = function () {
                    console.error('Error in loadHTML5Script');
                }
                if (appendAfter) {
                    if (Array.isArray(appendAfter)) {
                        if (appendAfter[1]) {
                            appendAfter[1].parentNode.insertBefore(s, appendAfter[1].nextSibling);
                        } else {
                            appendAfter[0].parentNode.insertBefore(s, appendAfter[0].nextSibling);
                        }
                    } else {
                        appendAfter.parentNode.insertBefore(s, appendAfter.nextSibling);
                    }
                } else {
                    document.getElementsByTagName('head')[0].appendChild(s);
                }
            };

            if (typeof window.cpexMessageListener === 'undefined' || !window.cpexMessageListener) {
                window.cpexMessageListener = true;

                var hashMessage = {};

                // processing iframe messages, catching cpex html creatives
                function processMessage (ev) {
                    var iframeEl;

                    if (typeof ev.data !== 'undefined'
                        && typeof ev.data.data !== 'undefined'
                        && typeof ev.data.type !== 'undefined'
                        && (ev.data.type === 'caf' || ev.data.type === 'skin')
                    ) {
                        iframeEl = getIframe(ev);
                        if (!iframeEl) { console.warn('iframe catch: origin iframe not found') }

                        if (iframeEl && iframeEl[0]
                            &&
                            (
                                /* If is skin and ready to render*/
                                (
                                    ev.data.type === 'skin'
                                    && typeof cpexSkinObject !== 'undefined'
                                    && cpexSkinObject.isReadyToRender
                                )
                                ||
                                /* if caf and creativeType known */
                                (
                                    typeof ev.data.creativeType !== 'undefined'
                                    && (
                                        ev.data.creativeType === 'native' || ev.data.creativeType === 'interscroller'
                                    )
                                )
                            )
                        ) {
                            var response = {};
                            response.response = 'cpexHTML5';
                            response[ev.data.type] = true;
                            response.hash = ev.data.hash || '';
                            if (typeof ev.data.source !== 'undefined') {
                                iframeEl[0].contentWindow.postMessage(response, '*');
                            }
                            ev.source.postMessage(response, '*');

                            iframeEl[0].style.display = 'none';
                            if (iframeEl[1]) {
                                iframeEl[1].style.display = 'none';
                            }
                            if (typeof ev.data.data === 'string') {
                                ev.data.data = JSON.parse(decodeURIComponent(escape(atob(ev.data.data))));
                            }
                            /* we don't need non-friendly */
                            ev.data.iurl = ev.data.url;
                            ev.data.url = window.location.href;
                            if (ev.data.type === 'skin') {
                                loadHTML5Script('skin', ev.data, iframeEl);
                            } else {
                                loadHTML5Script(ev.data.creativeType, ev.data, iframeEl);
                            }
                        }
                    }
                }

                window.addEventListener("message", function (ev) {

                    if (window.self === window.top) {
                        processMessage(ev);
                    } else {
                        if (ev && typeof ev.data !== 'undefined') {
                            if (typeof ev.data.response !== 'undefined' && ev.data.response === 'cpexHTML5') {
                                if (typeof hashMessage[ev.data.hash] !== 'undefined') {
                                    clearTimeout(hashMessage[ev.data.hash].timeout);
                                    var evReq = hashMessage[ev.data.hash].ev;
                                    processMessage(evReq);
                                }
                            } else if (typeof ev.data.hash !== 'undefined') {

                                var timeout = setTimeout(function () {
                                    processMessage(ev);
                                }, 95); //HTML5 creative has 100 ms.
                                hashMessage[ev.data.hash] = {
                                    ev: ev,
                                    timeout: timeout
                                };
                                window.parent.postMessage(ev.data, '*');

                            }
                        }
                    }
                }, false);
            }

        })();
    } catch (e) { console.error(e) }
}
